import { useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import styles from "./Simran.module.css"

function Simran(){
    const [password, setPassword] = useState("");
    const [isAuth, setIsAuth] = useState(false);

    const history = useHistory();

    const handleChange = (e) => {
        setPassword(e.target.value);
        console.log(e.target.value);
    }

    const handleSubmit = () => {
        if(password == "SimuIsAGappu"){
            history.push("/reallysimu");
            setPassword("");
        }
        else{
            alert("IMPOSTER!");
            setPassword("");
            history.push("/");
        }
    }

    return (
        <div className={styles.mainDiv}>
            <h2>If you're not Simran, GO BACK TO YOUR OWN PAGE!</h2>
            <input onChange={handleChange} className={styles.inputBox} type="password" className={styles.Password} placeholder="Password?"></input>
            <button onClick={handleSubmit} className={styles.submitButton}>Go!</button>
        </div>
    )
}

export default Simran;