import {Route, Switch} from "react-router-dom";
import Home from "../Pages/Home";

import SmallerCouncil from "../Pages/SmallerCouncil";
import Vanshika from "../Pages/Vanshika";
import Priya from "../Pages/Priya";
import Simran from "../Pages/Simran";
import Bulbul from "../Pages/Bulbul";
import Shruti from "../Pages/Shruti";
import ReallySimu from "../Pages/ReallySimu";
import Diksha from "../Pages/Diksha";

export default function AllRoutes(){
    return (
        <Switch>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route exact path="/smallercouncil">
                <SmallerCouncil/>
            </Route>
            <Route exact path="/vanshika">
                <Vanshika />
            </Route>
            <Route exact path="/priya">
                <Priya/>
            </Route>
            <Route exact path="/simran">
                <Simran/>
            </Route>
            <Route exact path="/bulbul">
                <Bulbul/>
            </Route>
            <Route exact path="/shruti">
                <Shruti/>
            </Route>
            <Route exact path="/reallysimu">
                <ReallySimu/>
            </Route>
            <Route exact path="/diksha">
                <Diksha/>
            </Route>
        </Switch>
    )
}