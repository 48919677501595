import Navbar from "../Navbar/Navbar";
import styles from "./Diksha.module.css";

function Diksha(){
    return (
        <div className={styles.mainDiv}>
            {/* <Navbar/> */}
            <div className={styles.greetings}>
                <h1>Hellloooo!</h1>
                <p>First of all tu mumbai jaake bhul jaati hai and then you don't even tell you're coming back!</p>
                <p>Priya had to tell me you're coming back and so I've added you here after that.</p>
                <h3>You toh HAVE to come. Koi choice nahi hai tere paas.</h3>
            </div>

            <div className={styles.location}>
                <div>
                    <h2>Date and Time: Friday, 12th August, 7PM Onwards and then nightstay!</h2>
                    <p>Address: 202, State Bank Nagar Paschim Vihar Road, Delhi, Delhi 110063, India</p>
                    <a href="https://www.google.com/maps/place/28%C2%B039'35.5%22N+77%C2%B005'39.8%22E/@28.6598611,77.0943889,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x3af1a9b664245ee4!8m2!3d28.6598625!4d77.0943832" >Find it on Google here!</a>
                </div>
                <div>
                    <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.1617376619465!2d77.09437349061372!3d28.659871445805244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcd2c4aad38f80734!2zMjjCsDM5JzM1LjUiTiA3N8KwMDUnMzkuOCJF!5e0!3m2!1sen!2sin!4v1659951583348!5m2!1sen!2sin" style={{width:"600", height:"450", style:"border:0", allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
                </div>
            </div>
        </div>
    )
}

export default Diksha;