import { Link } from "react-router-dom";
import styles from './Navbar.module.css'

export default function Navbar(){
    return (
        <div className={styles.MainDiv}>
            {/* <div>
                <Link to="/">Home</Link>
            </div> */}
            <div>
                <Link className={styles.LinkText} to="/smallercouncil">Smaller Council</Link>
            </div>
            <div>
                <Link className={styles.LinkText} to="/vanshika">Vanshika</Link>
            </div>
            <div>
                <Link className={styles.LinkText} to="/shruti">Shruti</Link>
            </div>
            <div>
                <Link className={styles.LinkText} to="/simran">Simran</Link>
            </div>
            <div>
                <Link className={styles.LinkText} to="/bulbul">Bulbul</Link>
            </div>
            <div>
                <Link className={styles.LinkText} to="/priya">Priya</Link>
            </div>
            <div>
                <Link className={styles.LinkText} to="/diksha">Diksha</Link>
            </div>
        </div>
    )
}