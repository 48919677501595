import Navbar from "../Navbar/Navbar";
import { withRouter } from "react-router-dom";
import styles from "./Home.module.css";
import Hasbulla from "../Images/Hasbulla.jpg"

function Home(){
    return (
        <div>
            <div className={styles.mainHeading}>
                <h2>You see why I have gathered you all here today.</h2>
                <h1>It's my birthday bitches!</h1>
                <p>And you all get invites because you're some of the most special people in my life but this is not about you, it's about me!</p>
                <h3>Now pick who you are.</h3>
            </div>
            <Navbar/>
            <div className={styles.image}>
                <img src={Hasbulla}></img>
            </div>
        </div>
    )
}

export default withRouter(Home);