import Tom from "../Images/Tom.PNG"
import styles from "./ReallySimu.module.css";

function ReallySimu(){
    return(
        <div className={styles.mainDiv}>
            <h1>AANA PADEGA</h1>
            <img src={Tom}/>
        </div>
    )
}

export default ReallySimu;