import './App.css';
import Navbar from './Navbar/Navbar';
import AllRoutes from './Routes/AllRoutes';

function App() {
  return (
    <AllRoutes>
    <div className="App">
      <Navbar/>
    </div>
    </AllRoutes>
  );
}

export default App;